export let userReducerActionTypes = {
  DESTROY: 'DESTROY',
  SET_USER: 'user/SET_USER',
  SET_LOADING: 'user/SET_LOADING',
  SET_ERROR: 'user/SET_ERROR',
};

export const EDIT_USER_DRAWER = 'admin/EDIT_USER_DRAWER';
export const CREATE_USER_DRAWER = 'admin/CREATE_USER_DRAWER';
export const USER_DETAILS_DRAWER = 'admin/USER_DETAILS_DRAWER';
export const DELETE_USER_DIALOG = 'admin/DELETE_USER_DIALOG';
export const ROLE_DETAILS_DRAWER = 'admin/ROLE_DETAILS_DRAWER';
export const CREATE_ROLE_DRAWER = 'admin/CREATE_ROLE_DRAWER';

// DELETE DIALOG
export const SET_DELETE_DIALOG = 'dialog/SET_DELETE_DIALOG';
export const SET_DELETE_ITEM = 'dialog/SET_DELETE_ITEM';

export const EXPAND_PERMISSION_TREE_DIALOG = 'admin/EXPAND_PERMISSION_TREE_DIALOG';
export const SET_USER_DETAILS = 'user/SET_USER_DETAILS';
export const SET_CMS_BLOCKS = 'user/SET_CMS_BLOCKS';
export const SET_SELECTED_USERS = 'user/SET_SELECTED_USERS';
export const SET_ACTIVITY_LOGS = 'user/SET_ACTIVITY_LOGS';
export const SET_KEY_DEADLINES = 'user/SET_KEY_DEADLINES';
export const SET_KEY_DEADLINES_ERROR = 'user/SET_KEY_DEADLINES_ERROR';
export const SET_KEY_DEADLINES_LOADING = 'user/SET_KEY_DEADLINES_LOADING';
export const SET_USERS = 'user/SET_USERS';
export const SET_USERS_ERROR = 'user/SET_USERS_ERROR';
export const SET_USERS_LOADING = 'user/SET_USERS_LOADING';
export const SET_ROLES = 'user/SET_ROLES';
export const SET_ROLES_ERROR = 'user/SET_ROLES_ERROR';
export const SET_ROLES_LOADING = 'user/SET_ROLES_LOADING';
export const SET_LOADED = 'user/SET_LOADED';
export const SET_USER = 'user/SET_USER';
export const DESTROY = 'DESTROY';
export const SET_EDIT_CREATIVE_ASSETS_REQUEST = 'creative_assets/SET_EDIT_CREATIVE_ASSETS_REQUEST';
export const UPLOAD_ASSETS_DIALOG = 'dialog/UPLOAD_ASSETS_DIALOG';
export const CREATE_ASSETS_FOLDER_DIALOG = 'dialog/CREATE_ASSETS_FOLDER_DIALOG';
export const DELETE_ASSETS_DIALOG = 'dialog/DELETE_ASSETS_DIALOG';
export const PREVIEW_ASSET_DIALOG = 'dialog/PREVIEW_ASSET_DIALOG';
export const REQUEST_ASSETS_DIALOG = 'dialog/REQUEST_ASSETS_DIALOG';

export const SET_DIGITAL_ASSETS_FILES = 'digital_assets/SET_DIGITAL_ASSETS_FILES';
export const SET_DIGITAL_ASSETS_FOLDERS = 'digital_assets/SET_DIGITAL_ASSETS_FOLDERS';
export const SET_EDIT_ROLE_TYPE = 'roles/SET_EDIT_ROLE_TYPE';
export const SET_ACTION_PERMISSIONS = 'roles/SET_ACTION_PERMISSIONS';
export const SET_SELECT_ALL = 'roles/SET_SELECT_ALL';
export const SET_OPEN_EDIT_ROLE_DIALOG = 'roles/SET_OPEN_EDIT_ROLE_DIALOG';
export const SET_CRITERIA = 'roles/SET_CRITERIA';
export const SET_READ_ONLY = 'roles/SET_READ_ONLY';
export const EXPAND_ALL_PERMISSIONS = 'roles/EXPAND_ALL_PERMISSIONS';
export const SAVE_ROLE_PERMISSIONS = 'roles/SAVE_ROLE_PERMISSIONS';
export const SET_EDIT_ASSET_FILE_DIALOG = 'dialog/SET_EDIT_ASSET_FILE_DIALOG';
export const SET_EDIT_ASSET_FOLDER_DIALOG = 'dialog/SET_EDIT_ASSET_FOLDER_DIALOG';

export const DELETE_NOTICE_DIALOG = 'dialog/DELETE_NOTICE_DIALOG';
export const UPDATE_NOTICE_DIALOG = 'dialog/UPDATE_NOTICE_DIALOG';
export const ADD_NOTICE_DIALOG = 'dialog/ADD_NOTICE_DIALOG';

export const DELETE_DEADLINE_DIALOG = 'dialog/DELETE_DEADLINE_DIALOG';
export const UPDATE_DEADLINE_DIALOG = 'dialog/UPDATE_DEADLINE_DIALOG';
export const ADD_DEADLINE_DIALOG = 'dialog/ADD_DEADLINE_DIALOG';

export const TERMS_AND_SERVICE_DIALOG = 'dialog/TERMS_AND_SERVICE_DIALOG';
export const TERMS_OF_USE_DIALOG = 'dialog/TERMS_OF_USE_DIALOG';
export const PRIVACY_POLICY_DIALOG = 'dialog/PRIVACY_POLICY_DIALOG';
export const ARCHIVE_DIALOG = 'dialog/ARCHIVE_DIALOG';
export const SET_ACTIVE_PROJECT_DATA = 'project/SET_ACTIVE_PROJECT_DATA';
export const SET_PREVIEW_INTERNAL_REVIEW_DIALOG = 'dialog/SET_PREVIEW_INTERNAL_REVIEW_DIALOG';

export const SET_ACTIVE_PARTNER = 'partners/SET_ACTIVE_PARTNER';
export const SET_ACTIVE_PARTNER_ID = 'partners/SET_ACTIVE_PARTNER_ID';

export const SET_PARTNERS_LIST = 'partners/SET_PARTNERS_LIST';
export const SET_TOURNAMENTS_LIST = 'partners/SET_TOURNAMENTS_LIST';

export const SET_USERS_ROLES = 'user/SET_USERS_ROLES';

export const SET_CREATIVE_TYPES = 'partners/SET_CREATIVE_TYPES';

export const SET_SELECTED_TICKETS_MERCHENDISE =
  'merchendiseSelectedTickets/SET_SELECTED_TICKETS_MERCHENDISE';

export const SET_JUSTIFICATION_MERCHENDISE =
  'merchendiseSelectedTickets/SET_JUSTIFICATION_MERCHENDISE';

export const UPDATE_TICKET_MERCHENDISE = 'merchendiseSelectedTickets/UPDATE_TICKET_MERCHENDISE';

export const CANCEL_REQUEST_MERCHANDISE = 'merchendiseSelectedTickets/CANCEL_REQUEST_MERCHANDISE';

export const SET_SELECTED_TICKETS_MATCH_TICKETS =
  'matchTicketsSelectedTicketsReducer/SET_SELECTED_TICKETS_MATCH_TICKETS';

export const SET_JUSTIFICATION_MATCH_TICKETS =
  'matchTicketsSelectedTicketsReducer/SET_JUSTIFICATION_MATCH_TICKETS';

export const CANCEL_REQUEST_MATCH_TICKETS =
  'matchTicketsSelectedTickets/CANCEL_REQUEST_MATCH_TICKETS';

export const UPDATE_TICKET_MATCH_TICKETS =
  'matchTicketsSelectedTickets/UPDATE_TICKET_MATCH_TICKETS';

// ARTICLES
export const SET_ARTICLES = 'articles/SET_ARTICLES';
export const SET_ARTICLE_DETAILS = 'articles/SET_ARTICLE_DETAILS';
export const SET_ARTICLE_CREATE_DRAWER = 'articles/SET_ARTICLE_CREATE_DRAWER';
export const SET_ARTICLE_DETAILS_DRAWER = 'articles/SET_ARTICLE_DETAILS_DRAWER';
export const SET_ARTICLE_DELETE_DIALOG = 'articles/SET_ARTICLE_DELETE_DIALOG';
export const SET_SORTING_ERROR = 'articles/SET_SORTING_ERROR';
export const SET_ARTICLE_EDIT_DRAWER = 'articles/SET_ARTICLE_EDIT_DRAWER';
export const SET_DISPLAY_REPORT_MODAL = 'articles/SET_DISPLAY_REPORT_MODAL';
export const SET_DISPLAY_REPORT_ITEM = 'articles/SET_DISPLAY_REPORT_ITEM';
