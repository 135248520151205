import update from 'immutability-helper';
import { MatchTicketsInventoryReducer } from '../Types/match_tickets_inventory_reducer.types';
import {
  SET_EDIT_MATCH_TICKET_INVENTORY_ITEM,
  SET_MATCH_TICKET_CREATE_INVENTORY_DIALOG,
  SET_MATCH_TICKET_INVENTORY_LIST,
  SET_MATCH_TICKET_UPDATE_INVENTORY_DIALOG,
} from './action-types';

const initialState: MatchTicketsInventoryReducer = {
  match_ticket_inventory_item: null,
  match_ticket_inventory_list: null,
  match_ticket_inventory_create_dialog: false,
  match_ticket_inventory_update_dialog: false,
};

const matchTicketsInventoryReducer = (state = initialState, action: any) => {
  if (action.type === SET_EDIT_MATCH_TICKET_INVENTORY_ITEM) {
    return update(state, {
      match_ticket_inventory_item: { $set: action.payload },
    });
  }

  if (action.type === SET_MATCH_TICKET_CREATE_INVENTORY_DIALOG) {
    return update(state, {
      match_ticket_inventory_create_dialog: { $set: action.payload },
    });
  }

  if (action.type === SET_MATCH_TICKET_UPDATE_INVENTORY_DIALOG) {
    return update(state, {
      match_ticket_inventory_update_dialog: { $set: action.payload },
    });
  }

  if (action.type === SET_MATCH_TICKET_INVENTORY_LIST) {
    return update(state, {
      match_ticket_inventory_list: { $set: action.payload },
    });
  }

  return state;
};

export default matchTicketsInventoryReducer;
