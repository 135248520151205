import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { setUser } from '../Store/action';
import api from './axios-utils';

const AdminProtectedRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();
  const route = useNavigate();

  useEffect(() => {
    if (user) {
      api.get('/me').then(res => {
        dispatch(setUser(res.data.data));
        localStorage.setItem('user', JSON.stringify(res.data.data));
      });
    }
  }, [dispatch, route, user]);

  if (user && ['admin', 'super_admin'].includes(user?.role)) {
    return children;
  }

  return <Navigate to="/" />;
};

export default AdminProtectedRoute;
