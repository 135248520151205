// MATCH TICKETS MATCHES
export const SET_EDIT_MATCH_TICKET_MATCHES_ITEM =
  "matchTicketsMatches/SET_EDIT_MATCH_TICKET_MATCHES_ITEM";
export const SET_MATCH_TICKET_CREATE_MATCHES_DIALOG =
  "matchTicketsMatches/SET_MATCH_TICKET_CREATE_MATCHES_DIALOG";
export const SET_MATCH_TICKET_UPDATE_MATCHES_DIALOG =
  "matchTicketsMatches/SET_MATCH_TICKET_UPDATE_MATCHES_DIALOG";
export const SET_MATCH_TICKET_MATCHES_LIST =
  "matchTicketsMatches/SET_MATCH_TICKET_MATCHES_LIST";

// MATCH TICKET TYPES
export const SET_MATCH_TICKETS_TYPES_CREATE_DIALOG =
  "matchTicketsTypes/SET_MATCH_TICKETS_TYPES_CREATE_DIALOG";
export const SET_MATCH_TICKETS_TYPES_UPDATE_DIALOG =
  "matchTicketsTypes/SET_MATCH_TICKETS_TYPES_UPDATE_DIALOG";
export const SET_EDIT_MATCH_TICKET_TYPES_ITEM =
  "matchTicketsTypes/SET_EDIT_MATCH_TICKET_TYPES_ITEM";
export const SET_MATCH_TICKET_TYPES_LIST =
  "matchTicketsTypes/SET_MATCH_TICKET_TYPES_LIST";

// MATCH TICKETS INVENTORY
export const SET_EDIT_MATCH_TICKET_INVENTORY_ITEM =
  "matchTicketsInventory/SET_EDIT_MATCH_TICKET_INVENTORY_ITEM";
export const SET_MATCH_TICKET_CREATE_INVENTORY_DIALOG =
  "matchTicketsInventory/SET_MATCH_TICKET_CREATE_INVENTORY_DIALOG";
export const SET_MATCH_TICKET_UPDATE_INVENTORY_DIALOG =
  "matchTicketsInventory/SET_MATCH_TICKET_UPDATE_INVENTORY_DIALOG";
export const SET_MATCH_TICKET_INVENTORY_LIST =
  "matchTicketsInventory/SET_MATCH_TICKET_INVENTORY_LIST";

// MATCH TICKETS
export const SET_MATCH_TICKET_LIST = "matchTickets/SET_MATCH_TICKET_LIST";
export const SET_SELECTED_MATCH_TICKET =
  "matchTickets/SET_SELECTED_MATCH_TICKET";
export const SET_MATCH_TICKETS_REQUESTS =
  "matchTickets/SET_MATCH_TICKETS_REQUESTS";
export const SET_MATCH_TICKETS_REQUESTS_ITEM =
  "matchTickets/SET_MATCH_TICKETS_REQUESTS_ITEM";
export const SET_MATCH_TICKETS_ADD_GUEST_DIALOG =
  "matchTickets/SET_MATCH_TICKETS_ADD_GUEST_DIALOG";
export const SET_MATCH_TICKETS_REQUESTS_GUESTS_LIST =
  "matchTickets/SET_MATCH_TICKETS_REQUESTS_GUESTS_LIST";
export const SET_MATCH_TICKETS_EDIT_GUEST_ITEM =
  "matchTickets/SET_MATCH_TICKETS_EDIT_GUEST_ITEM";
export const SET_MATCH_TICKETS_EDIT_GUEST_DIALOG =
  "matchTickets/SET_MATCH_TICKETS_EDIT_GUEST_DIALOG";
export const SET_EDIT_MATCH_TICKETS_REQUEST =
  "matchTickets/SET_EDIT_MATCH_TICKETS_REQUEST";
