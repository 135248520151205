import { setUser } from "../../Store/action";
import api from "../../Utils/axios-utils";

export const logoutUser = () => {
  return (dispatch) => {
    return api
      .post("/logout", {})
      .then((res) => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        dispatch(setUser(null));
        return res;
      })
      .catch((err) => {
        console.log("[LOGOUT ERROR]: ", err);
      });
  };
};
