import React, {useEffect} from 'react';
import {useLocation, Link} from 'react-router-dom';
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Grid,
  MenuItem,
  Menu,
  AppBar,
  Toolbar,
  IconButton,
  ListItem,
} from '@mui/material';
import {AccountCircleOutlined} from '@mui/icons-material';
import {setUser} from '../../Store/action';
import {useDispatch} from 'react-redux';
import {logoutUser} from './actions';
import LogoImage from '../../assets/primary/trimed-logo.png';

const classes = {
  content: {
    flexGrow: 1,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    position: 'relative',
  },
  link: {
    fontSize: '24px',
    color: '#000000',
    paddingLeft: '15px',
    '&.MuiListItemButton-root': {
      flexGrow: 0,
      display: 'inline-block',
      '&:after': {
        content: '""',
        width: 0,
        height: '2px',
        display: 'block',
        background: '#BA131D',
        transition: '300ms',
      },
      '&:hover::after': {
        width: '100%',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  selectedNav: {
    color: '#BA131D',
    fontSize: '24px',
    '&.MuiListItemButton-root': {
      display: 'inline-block',
      flexGrow: 0,
      '&:after': {
        content: '""',
        width: 0,
        height: '2px',
        display: 'block',
        background: '#BA131D',
        transition: '300ms',
      },
      '&:hover:after': {
        width: '100%',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
};

const MainMenuHeader = ({children}) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const location = useLocation();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutUser = () => {
    dispatch(logoutUser()).then(() => {
      handleClose();
    });
  };

  useEffect(() => {
    dispatch(setUser(user));
  }, [user]);

  return user ? (
    <>
      <AppBar position="fixed" sx={{zIndex: 1300, backgroundColor: 'white'}}>
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            sx={{minHeight: 70}}>
            <Grid item>
              <img src={LogoImage} height="60px" style={{margin: 10}}/>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2} alignItems="center">
                <IconButton onClick={handleClick} size="large">
                  <AccountCircleOutlined/>
                </IconButton>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <MenuItem
                    onClick={() => {
                      handleLogoutUser();
                    }}>
                    Logout
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          position: 'absolute',
          top: '70px',
          bottom: '0',
          width: '100%',
        }}>
        <Drawer
          variant="permanent"
          anchor="left"
          sx={{
            width: 250,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 250,
              boxSizing: 'border-box',
              boxShadow: '10px 0px 10px #0000001A',
            },
          }}
          PaperProps={{
            sx: {
              backgroundColor: '#DDDDDD',
              borderRight: 0,
              boxShadow: 'none',
              height: 'calc(100% - 64px)',
              top: '90px',
            },
          }}>
          <Stack
            justifyContent="space-between"
            sx={{
              height: '100%',
              '&:last-child': {
                paddingBottom: '40px',
              },
            }}>
            <List>
              <ListItem sx={{padding: 0}}>
                <ListItemButton
                  disableTouchRipple
                  disableRipple
                  component={Link}
                  sx={location.pathname === '/home' ? classes.selectedNav : classes.link}
                  to="/home">
                  <ListItemText primaryTypographyProps={{variant: 'h3'}} primary="Home"/>
                </ListItemButton>
              </ListItem>

              {location.pathname.startsWith('/home') && (
                <List sx={{paddingLeft: '20px'}}>
                  {/*THIS IS COMMENTED ONLY TEMPORARILY*/}
                  {/*<ListItem sx={{ padding: 0 }}>*/}
                  {/*  <ListItemButton*/}
                  {/*    disableTouchRipple*/}
                  {/*    disableRipple*/}
                  {/*    component={Link}*/}
                  {/*    to="/home/resources"*/}
                  {/*    sx={*/}
                  {/*      location.pathname.startsWith("/home/resources")*/}
                  {/*        ? classes.selectedNav*/}
                  {/*        : classes.link*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <ListItemText*/}
                  {/*      primaryTypographyProps={{*/}
                  {/*        variant: "h3",*/}
                  {/*      }}*/}
                  {/*      primary="> Resources"*/}
                  {/*    />*/}
                  {/*  </ListItemButton>*/}
                  {/*</ListItem>*/}
                  <ListItem sx={{padding: 0}}>
                    <ListItemButton
                      disableTouchRipple
                      disableRipple
                      component={Link}
                      to="/home/approvals"
                      sx={
                        location.pathname.startsWith('/home/approvals')
                          ? classes.selectedNav
                          : classes.link
                      }>
                      <ListItemText
                        primaryTypographyProps={{
                          variant: 'h3',
                        }}
                        primary="> Approvals"
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem sx={{padding: 0}}>
                    <ListItemButton
                      disableTouchRipple
                      disableRipple
                      component={Link}
                      to="/home/asset-guidelines"
                      sx={
                        location.pathname.startsWith('/home/asset-guidelines')
                          ? classes.selectedNav
                          : classes.link
                      }>
                      <ListItemText
                        primaryTypographyProps={{
                          variant: 'h3',
                        }}
                        primary="> Asset Guidelines"
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem sx={{padding: 0}}>
                    <ListItemButton
                      disableTouchRipple
                      disableRipple
                      component={Link}
                      to="/home/key-contacts"
                      sx={
                        location.pathname.startsWith('/home/key-contacts')
                          ? classes.selectedNav
                          : classes.link
                      }>
                      <ListItemText
                        primaryTypographyProps={{
                          variant: 'h3',
                        }}
                        primary="> Key Contacts"
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem sx={{padding: 0}}>
                    <ListItemButton
                      disableTouchRipple
                      disableRipple
                      component={Link}
                      to="/home/resources"
                      sx={
                        location.pathname.startsWith('/home/resources')
                          ? classes.selectedNav
                          : classes.link
                      }>
                      <ListItemText
                        primaryTypographyProps={{
                          variant: 'h3',
                        }}
                        primary="> Resources"
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              )}
              <ListItem sx={{padding: 0}}>
                <ListItemButton
                  disableTouchRipple
                  disableRipple
                  component={Link}
                  to="/approvals/new-creative-submission"
                  sx={
                    location.pathname.startsWith('/approvals') ? classes.selectedNav : classes.link
                  }
                  alignItems="center">
                  <ListItemText primaryTypographyProps={{variant: 'h3'}} primary="Approvals"/>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{padding: 0}}>
                <ListItemButton
                  disableTouchRipple
                  disableRipple
                  component={Link}
                  to="/match-tickets/assets"
                  sx={
                    location.pathname.startsWith('/match-tickets')
                      ? classes.selectedNav
                      : classes.link
                  }>
                  <ListItemText
                    primaryTypographyProps={{variant: 'h3'}}
                    primary="Match Tickets"
                  />
                </ListItemButton>
              </ListItem>

              <ListItem sx={{padding: 0}}>
                <ListItemButton
                  disableTouchRipple
                  disableRipple
                  component={Link}
                  to="/merchandise/assets"
                  sx={
                    location.pathname.startsWith('/merchandise')
                      ? classes.selectedNav
                      : classes.link
                  }>
                  <ListItemText primaryTypographyProps={{variant: 'h3'}} primary="Merchandise"/>
                </ListItemButton>
              </ListItem>
            </List>

            {user && ['admin', 'super_admin'].includes(user?.role) && (
              <List sx={{pb: 0}}>
                <ListItem sx={{padding: 0}}>
                  <ListItemButton
                    disableTouchRipple
                    disableRipple
                    component={Link}
                    to="/admin/approvals-requests"
                    sx={
                      location.pathname.startsWith('/admin') ? classes.selectedNav : classes.link
                    }>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'h3',
                      }}
                      primary="Admin"
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            )}
          </Stack>
        </Drawer>
        <Box component="main" sx={classes.content}>
          {children}
        </Box>
      </Box>
    </>
  ) : (
    <>{children}</>
  );
};

export default MainMenuHeader;
