import api from "../Utils/axios-utils";
import {
  EDIT_USER_DRAWER,
  SET_EDIT_ASSET_FILE_DIALOG,
  SET_EDIT_ASSET_FOLDER_DIALOG,
  SET_EDIT_CREATIVE_ASSETS_REQUEST,
  SET_USER,
  SET_USERS,
  USER_DETAILS_DRAWER,
} from "../ReduxStore/reducers/action_types";

export const userLogin = (data) => {
  return api.post(`/login`, data);
};

export const signupUser = (data) => {
  return api.post(`/register`, data);
};

export const verifyRecaptcha = (data) => {
  return api.post(`/verify-recaptcha`, data);
};

export const resetPassword = (data, token) => {
  let userInfo = {
    email: data.email,
    password: data.new_password,
    password_confirmation: data.confirm_new_password,
    token: token,
  };

  return api.post(`/reset-password`, userInfo);
};

export const mediaLibraryFiles = (folderID = "") => {
  return api.get(
    `/media-library-files?media_library_id=2` +
      (folderID && folderID !== null ? `&parent_folder_id=${folderID}` : "")
  );
};

// SEND RESET PASSWORD EMAIL
export const sendResetPassword = (data) => {
  return api.post(`/forgot-password`, data);
};

export const setUser = (data) => ({
  type: SET_USER,
  payload: data,
});

export const setUsers = (user_data) => ({
  type: SET_USERS,
  payload: user_data,
});

export const toggleUserDetailsDrawer = (state) => ({
  type: USER_DETAILS_DRAWER,
  payload: state,
});

export const toggleEditUserDrawer = (state) => ({
  type: EDIT_USER_DRAWER,
  payload: state,
});

export const setEditCreativeAssets = (data) => ({
  type: SET_EDIT_CREATIVE_ASSETS_REQUEST,
  payload: data,
});

export const toggleEditAssetFolderDialog = (state, asset, currentFolderID) => ({
  type: SET_EDIT_ASSET_FOLDER_DIALOG,
  payload: {
    dialogState: state,
    asset: asset,
    current_folder_id: currentFolderID,
  },
});

export const toggleEditAssetFileDialog = (state, asset, currentFolderID) => ({
  type: SET_EDIT_ASSET_FILE_DIALOG,
  payload: {
    dialogState: state,
    asset: asset,
    current_folder_id: currentFolderID,
  },
});
