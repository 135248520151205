import axios from 'axios';
import { API_URL, authHeader } from './authHeader';

const api = axios.create({
  baseURL: API_URL,
  headers: authHeader(),
});

api.interceptors.request.use(
  request => {
    let token = localStorage.getItem('token');

    if (token) {
      request.headers['Authorization'] = `Bearer ${token}`;
    }

    return request;
  },
  error => {
    // any status code that falls outside of 2xx will triger this fnction to happen
    console.log('[ERROR]: ', error.response);

    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  response => {
    // any status code that is within 2xx will triger this function to happen
    return response;
  },
  error => {
    console.log('[ERROR]: ', error.response);
    // any status code that falls outside of 2xx will triger this fnction to happen

    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    }

    if (error.response.status === 404) {
      window.location.href = '/404';
    }

    return Promise.reject(error);
  },
);

export default api;
