import update from 'immutability-helper';
import {
  DESTROY,
  SET_SELECTED_TICKETS_MERCHENDISE,
  SET_JUSTIFICATION_MERCHENDISE,
  UPDATE_TICKET_MERCHENDISE,
  CANCEL_REQUEST_MERCHANDISE,
} from './action_types';
import { MerchandiseSelectedTicketsReducer } from './Types/merchandise_selected_tickets_reducer.types';

const initialState: MerchandiseSelectedTicketsReducer = {
  justification: {},
  tickets: [],
};

const merchendiseSelectedTicketsReducer = (state = initialState, action: any) => {
  if (action.type === DESTROY) {
    return initialState;
  }

  if (action.type === SET_SELECTED_TICKETS_MERCHENDISE) {
    return update(state, {
      tickets: { $set: action.payload },
    });
  }

  if (action.type === SET_JUSTIFICATION_MERCHENDISE) {
    return update(state, {
      justification: { $set: action.payload },
    });
  }

  if (action.type === UPDATE_TICKET_MERCHENDISE) {
    // not correct syntax, check
    let newArr = state.tickets.map(ticket =>
      ticket.id !== action.payload.ticket.id
        ? ticket
        : update(ticket, { $set: action.payload.ticket }),
    );

    return update(state, {
      tickets: { $set: newArr },
    });
  }

  if (action.type === CANCEL_REQUEST_MERCHANDISE) {
    let oldArr = [...state.tickets];

    let newArr = oldArr.filter(item => item !== action.payload);

    return update(state, {
      tickets: { $set: newArr },
    });
  }

  return state;
};

export default merchendiseSelectedTicketsReducer;
