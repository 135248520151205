import update from "immutability-helper";
import { MatchTicketsMatchesReducer } from "../Types/match_tickets_matches_reducer.types";
import {
  SET_EDIT_MATCH_TICKET_MATCHES_ITEM,
  SET_MATCH_TICKET_CREATE_MATCHES_DIALOG,
  SET_MATCH_TICKET_MATCHES_LIST,
  SET_MATCH_TICKET_UPDATE_MATCHES_DIALOG,
} from "./action-types";

const initialState: MatchTicketsMatchesReducer = {
  match_ticket_matches_create_dialog: false,
  match_ticket_matches_update_dialog: false,
  match_ticket_matches_item: null,
  match_ticket_matches_list: null,
};

const matchTicketsMatchesReducer = (state = initialState, action: any) => {
  if (action.type === SET_EDIT_MATCH_TICKET_MATCHES_ITEM) {
    return update(state, {
      match_ticket_matches_item: { $set: action.payload },
    });
  }

  if (action.type === SET_MATCH_TICKET_CREATE_MATCHES_DIALOG) {
    return update(state, {
      match_ticket_matches_create_dialog: { $set: action.payload },
    });
  }

  if (action.type === SET_MATCH_TICKET_UPDATE_MATCHES_DIALOG) {
    return update(state, {
      match_ticket_matches_update_dialog: { $set: action.payload },
    });
  }

  if (action.type === SET_MATCH_TICKET_MATCHES_LIST) {
    return update(state, {
      match_ticket_matches_list: { $set: action.payload },
    });
  }

  return state;
};

export default matchTicketsMatchesReducer;
