import update from 'immutability-helper';
import {
  CREATE_ASSETS_FOLDER_DIALOG,
  DELETE_ASSETS_DIALOG,
  PREVIEW_ASSET_DIALOG,
  REQUEST_ASSETS_DIALOG,
  SET_EDIT_ASSET_FILE_DIALOG,
  SET_EDIT_ASSET_FOLDER_DIALOG,
  UPLOAD_ASSETS_DIALOG,
  DELETE_NOTICE_DIALOG,
  UPDATE_NOTICE_DIALOG,
  ADD_NOTICE_DIALOG,
  DELETE_DEADLINE_DIALOG,
  UPDATE_DEADLINE_DIALOG,
  ADD_DEADLINE_DIALOG,
  TERMS_AND_SERVICE_DIALOG,
  TERMS_OF_USE_DIALOG,
  PRIVACY_POLICY_DIALOG,
  ARCHIVE_DIALOG,
  SET_PREVIEW_INTERNAL_REVIEW_DIALOG,
  SET_DELETE_DIALOG,
  SET_DELETE_ITEM,
} from './action_types';
import { DialogReducer } from './Types/dialog_reducer.types';

const initialState: DialogReducer = {
  deleteNoticeDialog: false,
  updateNoticeDialog: false,
  addNoticeDialog: false,

  deleteDeadlineDialog: false,
  updateDeadlineDialog: false,
  addDeadlineDialog: false,

  termsAndServiceDialog: false,
  termsOfUseDialogState: false,
  privacyPolicyDialogState: false,

  activeId: null,
  activeData: null,

  archive_dialog: false,

  upload_assets: false,
  create_assets_folder: false,
  folderID: null,
  delete_assets_dialog: false,
  preview_asset_dialog: false,
  request_assets_dialog: false,
  asset: null,
  current_folder_id: null,
  edit_asset_file_dialog: false,
  edit_asset_folder_dialog: false,
  edit_asset: null,

  preview_internal_review_dialog: false,

  delete_dialog: false,
  delete_item: null,
};

const dialogReducer = (state = initialState, action: any) => {
  if (action.type === DELETE_NOTICE_DIALOG) {
    return update(state, {
      deleteNoticeDialog: { $set: action.payload.dialogState },
      activeId: { $set: action.payload.activeId },
    });
  }

  if (action.type === UPDATE_NOTICE_DIALOG) {
    return update(state, {
      updateNoticeDialog: { $set: action.payload.dialogState },
      activeData: { $set: action.payload.activeData },
    });
  }

  if (action.type === ADD_NOTICE_DIALOG) {
    return update(state, {
      addNoticeDialog: { $set: action.payload.dialogState },
      activeId: { $set: action.payload.activeId },
    });
  }

  if (action.type === DELETE_DEADLINE_DIALOG) {
    return update(state, {
      deleteDeadlineDialog: { $set: action.payload.dialogState },
      activeId: { $set: action.payload.activeId },
    });
  }

  if (action.type === UPDATE_DEADLINE_DIALOG) {
    return update(state, {
      updateDeadlineDialog: { $set: action.payload.dialogState },
      activeData: { $set: action.payload.activeData },
    });
  }

  if (action.type === ADD_DEADLINE_DIALOG) {
    return update(state, {
      addDeadlineDialog: { $set: action.payload.dialogState },
    });
  }

  if (action.type === TERMS_AND_SERVICE_DIALOG) {
    return update(state, {
      termsAndServiceDialog: { $set: action.payload.dialogState },
    });
  }

  if (action.type === TERMS_OF_USE_DIALOG) {
    return update(state, {
      termsOfUseDialogState: { $set: action.payload.dialogState },
    });
  }

  if (action.type === PRIVACY_POLICY_DIALOG) {
    return update(state, {
      privacyPolicyDialogState: { $set: action.payload.dialogState },
    });
  }

  if (action.type === ARCHIVE_DIALOG) {
    return update(state, {
      archive_dialog: { $set: action.payload },
    });
  }

  if (action.type === UPLOAD_ASSETS_DIALOG) {
    return update(state, {
      upload_assets: { $set: action.payload.dialogState },
      folderID: { $set: action.payload.folderID },
    });
  }

  if (action.type === CREATE_ASSETS_FOLDER_DIALOG) {
    return update(state, {
      create_assets_folder: { $set: action.payload.dialogState },
      folderID: { $set: action.payload.folderID },
    });
  }

  if (action.type === DELETE_ASSETS_DIALOG) {
    return update(state, {
      delete_assets_dialog: { $set: action.payload.dialogState },
      folderID: { $set: action.payload.folderID },
      current_folder_id: { $set: action.payload.current_folder_id },
    });
  }

  if (action.type === PREVIEW_ASSET_DIALOG) {
    return update(state, {
      preview_asset_dialog: { $set: action.payload.dialogState },
      asset: { $set: action.payload.asset },
    });
  }

  if (action.type === REQUEST_ASSETS_DIALOG) {
    return update(state, {
      request_assets_dialog: { $set: action.payload.dialogState },
    });
  }

  if (action.type === SET_EDIT_ASSET_FILE_DIALOG) {
    return update(state, {
      edit_asset_file_dialog: { $set: action.payload.dialogState },
      edit_asset: { $set: action.payload.asset },
      current_folder_id: { $set: action.payload.current_folder_id },
    });
  }

  if (action.type === SET_EDIT_ASSET_FOLDER_DIALOG) {
    return update(state, {
      edit_asset_folder_dialog: { $set: action.payload.dialogState },
      edit_asset: { $set: action.payload.asset },
      current_folder_id: { $set: action.payload.current_folder_id },
    });
  }

  if (action.type === SET_PREVIEW_INTERNAL_REVIEW_DIALOG) {
    return update(state, {
      preview_internal_review_dialog: { $set: action.payload },
    });
  }

  if (action.type === SET_DELETE_DIALOG) {
    return update(state, {
      delete_dialog: { $set: action.payload },
    });
  }

  if (action.type === SET_DELETE_ITEM) {
    return update(state, {
      delete_item: { $set: action.payload },
    });
  }

  return state;
};

export default dialogReducer;
