import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseContainer from '../Utils/SuspenseContainer';
import ProtectedRoute from '../Utils/ProtectedRoute';
import AdminProtectedRoute from '../Utils/AdminProtectedRoute';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
const UsefulDocuments = lazy(() => import('pages/Admin/UsefulDocuments'));
const RequestOverview = lazy(() => import('pages/Merchandise/Components/RequestOverview'));
const GuestCapture = lazy(() => import('pages/MatchTickets/Components/MyRequests/GuestCapture'));
const MakeRequest = lazy(() => import('pages/MatchTickets/Components/MakeRequest'));
const MatchTicketsComponent = lazy(
  () => import('pages/MatchTickets/Components/MatchTicketsComponent'),
);
const BrowseAssetsTable = lazy(
  () => import('pages/MatchTickets/Components/BrowseAssets/BrowseAssetsTable'),
);
const MatchTicketsMyRequests = lazy(
  () => import('pages/MatchTickets/Components/MyRequests/MyRequests'),
);
const CurrentMatches = lazy(
  () => import('pages/MatchTickets/Components/MyRequests/CurrentMatches'),
);
const PastMatches = lazy(() => import('pages/MatchTickets/Components/MyRequests/PastMatches'));
const MatchTicketsMyRequestsOverview = lazy(
  () =>
    import(
      'pages/MatchTickets/Components/MyRequests/MatchTicketsMyRequests/MatchTicketsMyRequestsOverview/MatchTicketsMyRequestsOverview'
    ),
);
const MatchTicketsMyRequestsGuestCapture = lazy(
  () =>
    import(
      'pages/MatchTickets/Components/MyRequests/MatchTicketsMyRequests/MatchTicketsMyRequestsOverview/MatchTicketsMyRequestsGuestCapture/MatchTicketsMyRequestsGuestCapture'
    ),
);
const MatchTicketsMyRequestsEdit = lazy(
  () =>
    import(
      'pages/MatchTickets/Components/MyRequests/MatchTicketsMyRequests/MatchTicketsMyRequestsOverview/MatchTicketsMyRequestsEdit/MatchTicketsMyRequestsEdit'
    ),
);
const MatchTicketsMyRequestsDetails = lazy(
  () =>
    import(
      'pages/MatchTickets/Components/MyRequests/MatchTicketsMyRequests/MatchTicketsMyRequestsOverview/MatchTicketsMyRequestsDetails/MatchTicketsMyRequestsDetails'
    ),
);
const Activations = lazy(() => import('../pages/Home/Activations/Activations'));
const HomeApprovals = lazy(() => import('../pages/Home/Approvals/Approvals'));
const KeyContacts = lazy(() => import('../pages/Home/KeyContacts/KeyContacts'));
const Resources = lazy(() => import('../pages/Home/Resources/Resources'));
const PageNotFound = lazy(() => import('pages/PageNotFound/PageNotFound'));
const AdminApprovalsRequestsList = lazy(
  () =>
    import('pages/Admin/ApprovalsRequests/AdminApprovalsRequestsList/AdminApprovalsRequestsList'),
);
const AdminApprovalsRequestDetails = lazy(
  () =>
    import(
      'pages/Admin/ApprovalsRequests/AdminApprovalsRequestDetails/AdminApprovalsRequestDetails'
    ),
);
const Reports = lazy(() => import('pages/Admin/Reports/Reports'));
const UsersTable = lazy(() => import('pages/Admin/Users/UsersTable/UsersTable'));
const MatchTicketsTypes = lazy(
  () => import('pages/Admin/MatchTickets/MatchTicketsTypes/MatchTicketsTypes'),
);
const MatchTicketsMatches = lazy(
  () => import('pages/Admin/MatchTickets/MatchTicketsMatches/MatchTicketsMatches'),
);
const MatchTicketsInventory = lazy(
  () => import('pages/Admin/MatchTickets/MatchTicketsInventory/MatchTicketsInventory'),
);
const MatchTicketsRequestsOverview = lazy(
  () =>
    import(
      'pages/Admin/MatchTickets/MatchTicketsRequests/MatchTicketsRequestsOverview/MatchTicketsRequestsOverview'
    ),
);
const MatchTicketsRequestsRequests = lazy(
  () => import('pages/Admin/MatchTickets/MatchTicketsRequests/MatchTicketsRequestsRequests'),
);
const MerchandiseMerch = lazy(
  () => import('pages/Admin/Merchandise/MerchandiseMerch/MerchandiseMerch'),
);
const MerchandiseTypes = lazy(
  () => import('pages/Admin/Merchandise/MerchandiseTypes/MerchandiseTypes'),
);
const MerchandiseRequests = lazy(
  () => import('pages/Admin/Merchandise/MerchandiseRequests/MerchandiseRequests'),
);
const AdminMerchandiseRequestEdit = lazy(
  () =>
    import(
      'pages/Admin/Merchandise/MerchandiseRequests/MerchandiseRequestEdit/MerchandiseRequestEdit'
    ),
);
const AdminMerchandise = lazy(() => import('pages/Admin/Merchandise/AdminMerchandise'));
const AdminMatchTickets = lazy(() => import('pages/Admin/MatchTickets/AdminMatchTickets'));
const ApprovalsReports = lazy(
  () => import('pages/Admin/Reports/Components/ApprovalsReports/ApprovalsReports'),
);
const MatchTicketsReports = lazy(
  () => import('pages/Admin/Reports/Components/MatchTicketsReports/MatchTicketsReports'),
);
const MerchandiseReports = lazy(
  () => import('pages/Admin/Reports/Components/MerchandiseReports/MerchandiseReports'),
);
const UsersReports = lazy(() => import('pages/Admin/Reports/Components/UsersReports/UsersReports'));

const Home = lazy(() => import('../pages/Home/Home'));
const Admin = lazy(() => import('../pages/Admin/Admin'));
const Login = lazy(() => import('../pages/UserAuthentication/Login/Login'));
const SignUp = lazy(() => import('../pages/UserAuthentication/SignUp/SignUp'));
const ResetPassword = lazy(() => import('../pages/UserAuthentication/ResetPassword/ResetPassword'));
const Approvals = lazy(() => import('../pages/Approvals/Approvals'));
const NewPassword = lazy(() => import('../pages/UserAuthentication/NewPassword/NewPassword'));
const Merchandise = lazy(() => import('../pages/Merchandise/Merchandise'));

const AllRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <SuspenseContainer>
            <Login />
          </SuspenseContainer>
        }
      />

      <Route
        path="/sign-up"
        element={
          <SuspenseContainer>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ?? ""}>
              <SignUp />
            </GoogleReCaptchaProvider>
          </SuspenseContainer>
        }
      />

      <Route
        path="/reset"
        element={
          <SuspenseContainer>
            <ResetPassword />
          </SuspenseContainer>
        }
      />

      <Route
        path="/approvals/*"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <Approvals />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />

      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <Home />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />

      <Route
        path="match-tickets"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <MatchTicketsComponent />
            </SuspenseContainer>
          </ProtectedRoute>
        }>
        <Route
          path="assets"
          element={
            <SuspenseContainer>
              <BrowseAssetsTable />
            </SuspenseContainer>
          }
        />
        <Route
          path="my-requests"
          element={
            <SuspenseContainer>
              <MatchTicketsMyRequests />
            </SuspenseContainer>
          }>
          <Route
            path="current"
            element={
              <SuspenseContainer>
                <CurrentMatches />
              </SuspenseContainer>
            }>
            <Route
              path="request-overview"
              element={
                <SuspenseContainer>
                  <RequestOverview />
                </SuspenseContainer>
              }
            />
            <Route
              path="request-overview/guest-capture"
              element={
                <SuspenseContainer>
                  <GuestCapture />
                </SuspenseContainer>
              }
            />
          </Route>
          <Route
            path="past"
            element={
              <SuspenseContainer>
                <PastMatches />
              </SuspenseContainer>
            }
          />
        </Route>
      </Route>
      <Route
        path="match-tickets/my-requests/current/:id/details"
        element={
          <SuspenseContainer>
            <MatchTicketsMyRequestsOverview />
          </SuspenseContainer>
        }>
        <Route
          path="match-details"
          element={
            <SuspenseContainer>
              <MatchTicketsMyRequestsDetails />
            </SuspenseContainer>
          }
        />
        <Route
          path="edit"
          element={
            <SuspenseContainer>
              <MatchTicketsMyRequestsEdit />
            </SuspenseContainer>
          }
        />
        <Route
          path="guest-capture"
          element={
            <SuspenseContainer>
              <MatchTicketsMyRequestsGuestCapture />
            </SuspenseContainer>
          }
        />
      </Route>
      <Route
        path="match-tickets/my-requests/past/:id/details"
        element={
          <SuspenseContainer>
            <MatchTicketsMyRequestsOverview />
          </SuspenseContainer>
        }>
        <Route
          path="match-details"
          element={
            <SuspenseContainer>
              <MatchTicketsMyRequestsDetails />
            </SuspenseContainer>
          }
        />
        <Route
          path="edit"
          element={
            <SuspenseContainer>
              <MatchTicketsMyRequestsEdit />
            </SuspenseContainer>
          }
        />
        <Route
          path="guest-capture"
          element={
            <SuspenseContainer>
              <MatchTicketsMyRequestsGuestCapture />
            </SuspenseContainer>
          }
        />
      </Route>
      <Route
        path="match-tickets/assets/make-request"
        element={
          <SuspenseContainer>
            <MakeRequest />
          </SuspenseContainer>
        }
      />

      <Route
        path="/merchandise/*"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <Merchandise />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />

      <Route
        path="/home/resources"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <Resources />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />

      <Route
        path="/home/approvals"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <HomeApprovals />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />

      <Route
        path="/home/asset-guidelines"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <Activations />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />

      <Route
        path="/home/key-contacts"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <KeyContacts />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />

      <Route
        path="/new-password/:token"
        element={
          <SuspenseContainer>
            <NewPassword />
          </SuspenseContainer>
        }
      />

      <Route
        path="admin"
        element={
          <ProtectedRoute>
            <AdminProtectedRoute>
              <SuspenseContainer>
                <Admin />
              </SuspenseContainer>
            </AdminProtectedRoute>
          </ProtectedRoute>
        }>
        <Route
          path="approvals-requests"
          element={
            <SuspenseContainer>
              <AdminApprovalsRequestsList />
            </SuspenseContainer>
          }
        />
        <Route
          path="approvals-requests/:id"
          element={
            <SuspenseContainer>
              <AdminApprovalsRequestDetails />
            </SuspenseContainer>
          }
        />
        <Route
          path="match-tickets"
          element={
            <SuspenseContainer>
              <AdminMatchTickets />
            </SuspenseContainer>
          }>
          <Route
            path="requests"
            element={
              <SuspenseContainer>
                <MatchTicketsRequestsRequests />
              </SuspenseContainer>
            }
          />
          <Route
            path="requests/overview/:id"
            element={
              <SuspenseContainer>
                <MatchTicketsRequestsOverview />
              </SuspenseContainer>
            }
          />
          <Route
            path="inventory"
            element={
              <SuspenseContainer>
                <MatchTicketsInventory />
              </SuspenseContainer>
            }
          />
          <Route
            path="matches"
            element={
              <SuspenseContainer>
                <MatchTicketsMatches />
              </SuspenseContainer>
            }
          />
          <Route
            path="types"
            element={
              <SuspenseContainer>
                <MatchTicketsTypes />
              </SuspenseContainer>
            }
          />
        </Route>
        <Route
          path="merchandise"
          element={
            <SuspenseContainer>
              <AdminMerchandise />
            </SuspenseContainer>
          }>
          <Route
            path="requests"
            element={
              <SuspenseContainer>
                <MerchandiseRequests />
              </SuspenseContainer>
            }
          />
          <Route
            path="merch"
            element={
              <SuspenseContainer>
                <MerchandiseMerch />
              </SuspenseContainer>
            }
          />
          <Route
            path="types"
            element={
              <SuspenseContainer>
                <MerchandiseTypes />
              </SuspenseContainer>
            }
          />
        </Route>
        <Route
          path="merchandise/requests/:id/edit"
          element={
            <SuspenseContainer>
              <AdminMerchandiseRequestEdit />
            </SuspenseContainer>
          }
        />

        <Route
          path="users"
          element={
            <SuspenseContainer>
              <UsersTable />
            </SuspenseContainer>
          }
        />

        <Route
          path="reports"
          element={
            <SuspenseContainer>
              <Reports />
            </SuspenseContainer>
          }>
          <Route
            path="approval-reports"
            element={
              <SuspenseContainer>
                <ApprovalsReports />
              </SuspenseContainer>
            }
          />
          <Route
            path="match-tickets-reports"
            element={
              <SuspenseContainer>
                <MatchTicketsReports />
              </SuspenseContainer>
            }
          />
          <Route
            path="merch-reports"
            element={
              <SuspenseContainer>
                <MerchandiseReports />
              </SuspenseContainer>
            }
          />
          <Route
            path="user-reports"
            element={
              <SuspenseContainer>
                <UsersReports />
              </SuspenseContainer>
            }
          />
        </Route>

        <Route
          path="useful-documents"
          element={
            <SuspenseContainer>
              <UsefulDocuments />
            </SuspenseContainer>
          }
        />
      </Route>

      <Route
        path="*"
        element={
          <SuspenseContainer>
            <PageNotFound />
          </SuspenseContainer>
        }
      />
    </Routes>
  );
};

export default AllRoutes;
