import update from 'immutability-helper';
import {
  DESTROY,
  SET_ACTIVE_PARTNER,
  SET_ACTIVE_PARTNER_ID,
  SET_CREATIVE_TYPES,
  SET_PARTNERS_LIST,
  SET_TOURNAMENTS_LIST,
} from './action_types';
import { PartnersReducer } from './Types/partners_reducer.types';

const initialState: PartnersReducer = {
  activePartner: null,
  activePartnerID: '',
  partners_list: null,
  creative_type_list: null,
  tournaments_list: null,
};

const partnersReducer = (state = initialState, action: any) => {
  if (action.type === DESTROY) {
    return initialState;
  }

  if (action.type === SET_ACTIVE_PARTNER) {
    return update(state, {
      activePartner: { $set: action.payload },
    });
  }

  if (action.type === SET_ACTIVE_PARTNER_ID) {
    return update(state, {
      activePartnerID: { $set: action.payload },
    });
  }

  if (action.type === SET_PARTNERS_LIST) {
    return update(state, {
      partners_list: { $set: action.payload },
    });
  }

  if (action.type === SET_CREATIVE_TYPES) {
    return update(state, {
      creative_type_list: { $set: action.payload },
    });
  }

  if (action.type === SET_TOURNAMENTS_LIST) {
    return update(state, {
      tournaments_list: { $set: action.payload },
    });
  }

  return state;
};

export default partnersReducer;
