import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
// import AppLoader from "../SharedComponents/AppLoader/AppLoader";
import AllRoutes from './AllRoutes';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { theme } from './Theme';
import { ConfirmProvider } from 'material-ui-confirm';
import MainMenuHeader from '../Components/MainMenuHeader/MainMenuHeader';
import ReactGA from 'react-ga';
import Store from 'ReduxStore/Store';

ReactGA.initialize();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={Store}>
          {/* <NotificationProvider> */}
          <ConfirmProvider>
            {/* <AppLoader> */}
            <BrowserRouter>
              <MainMenuHeader>
                <AllRoutes />
              </MainMenuHeader>
            </BrowserRouter>
            {/* </AppLoader> */}
          </ConfirmProvider>
          {/* </NotificationProvider> */}
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
