import update from 'immutability-helper';
import {
  CREATE_ROLE_DRAWER,
  CREATE_USER_DRAWER,
  DELETE_USER_DIALOG,
  EDIT_USER_DRAWER,
  EXPAND_PERMISSION_TREE_DIALOG,
  ROLE_DETAILS_DRAWER,
  USER_DETAILS_DRAWER,
} from './action_types';
import { AdminReducer } from './Types/admin_reducer.types';

const initialState: AdminReducer = {
  edit_user_drawer: false,
  create_user_drawer: false,
  user_details_drawer: false,
  delete_user_dialog: false,
  role_details_drawer: false,
  create_role_drawer: false,
  expand_permission_tree_dialog: false,
};

const adminReducer = (state = initialState, action: any) => {
  if (action.type === EDIT_USER_DRAWER) {
    return update(state, {
      edit_user_drawer: { $set: action.payload },
    });
  }

  if (action.type === CREATE_USER_DRAWER) {
    return update(state, {
      create_user_drawer: { $set: action.payload },
    });
  }

  if (action.type === USER_DETAILS_DRAWER) {
    return update(state, {
      user_details_drawer: { $set: action.payload },
    });
  }

  if (action.type === DELETE_USER_DIALOG) {
    return update(state, {
      delete_user_dialog: { $set: action.payload },
    });
  }

  if (action.type === ROLE_DETAILS_DRAWER) {
    return update(state, {
      role_details_drawer: { $set: action.payload },
    });
  }

  if (action.type === CREATE_ROLE_DRAWER) {
    return update(state, {
      create_role_drawer: { $set: action.payload },
    });
  }

  if (action.type === EXPAND_PERMISSION_TREE_DIALOG) {
    return update(state, {
      expand_permission_tree_dialog: { $set: action.payload },
    });
  }

  return state;
};

export default adminReducer;
