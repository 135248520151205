import { combineReducers } from 'redux';
import userReducer from './user_reducer';
import adminReducer from './admin_reducer';
import dialogReducer from './dialog_reducer';
import partnersReducer from './partners_reducer';
import merchendiseSelectedTicketsReducer from './merchendise_selected_tickets_reducer';
import matchTicketsSelectedTicketsReducer from './match_tickets_selected_tickets_reducer';
import matchTicketsInventoryReducer from './MatchTickets/match_tickets_inventory_reducer';
import merchTypeReducer from './Merchandise/merch_type_reducer';
import matchTicketsTypesReducer from './match_tickets_types_reducer';
import matchTicketsReducer from './MatchTickets/match_tickets_reducer';
import matchTicketsMatchesReducer from './MatchTickets/match_tickets_matches_reducer';
import merchReducer from './Merchandise/merch_reducer';
import articlesReducer from './articles_reducer';

const rootReducer = combineReducers({
  user: userReducer,
  admin: adminReducer,
  dialog: dialogReducer,
  partners: partnersReducer,
  merchendiseSelectedTickets: merchendiseSelectedTicketsReducer,
  matchTicketsSelectedTickets: matchTicketsSelectedTicketsReducer,
  matchTicketsMatches: matchTicketsMatchesReducer,
  matchTicketsInventory: matchTicketsInventoryReducer,
  matchTicketsTypes: matchTicketsTypesReducer,
  matchTickets: matchTicketsReducer,
  merch: merchReducer,
  merch_type: merchTypeReducer,
  articles: articlesReducer,
});

export default rootReducer;
