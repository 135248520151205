import update from 'immutability-helper';
import { Action } from 'redux';
import {
  SET_USERS,
  SET_USERS_ROLES,
  SET_USER_DETAILS,
  userReducerActionTypes,
} from './action_types';
import { UsersReducer } from './Types/userReducer.types';

const initialState: UsersReducer = {
  user: null,
  loading: false,
  error: false,
  users: null,
  user_details: null,
  roles_list: null,
};

const userReducer = (state = initialState, action: any) => {
  if (action.type === userReducerActionTypes.DESTROY) {
    return initialState;
  }

  if (action.type === userReducerActionTypes.SET_USER) {
    return update(state, {
      user: { $set: action.payload },
    });
  }

  if (action.type === userReducerActionTypes.SET_LOADING) {
    return update(state, {
      loading: { $set: action.payload },
    });
  }

  if (action.type === userReducerActionTypes.SET_ERROR) {
    return update(state, {
      error: { $set: action.payload },
    });
  }

  if (action.type === SET_USERS) {
    return update(state, {
      users: { $set: action.payload },
    });
  }

  if (action.type === SET_USER_DETAILS) {
    return update(state, {
      user_details: { $set: action.payload },
    });
  }

  if (action.type === SET_USERS_ROLES) {
    return update(state, {
      roles_list: { $set: action.payload },
    });
  }

  return state;
};

export default userReducer;
