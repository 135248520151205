import update from "immutability-helper";
import { MatchTicketsReducer } from "../Types/match_tickets_reducer.types";
import {
  SET_EDIT_MATCH_TICKETS_REQUEST,
  SET_MATCH_TICKETS_ADD_GUEST_DIALOG,
  SET_MATCH_TICKETS_EDIT_GUEST_DIALOG,
  SET_MATCH_TICKETS_EDIT_GUEST_ITEM,
  SET_MATCH_TICKETS_REQUESTS,
  SET_MATCH_TICKETS_REQUESTS_GUESTS_LIST,
  SET_MATCH_TICKETS_REQUESTS_ITEM,
  SET_MATCH_TICKET_LIST,
  SET_SELECTED_MATCH_TICKET,
} from "./action-types";

const initialState: MatchTicketsReducer = {
  match_ticket_list: null,
  selected_match_tickets: [],
  match_tickets_requests: null,
  match_tickets_requests_item: null,
  match_tickets_requests_add_guest_dialog: false,

  match_tickets_requests_guests_list: [],
  match_tickets_requests_edit_guest_dialog: false,
  match_tickets_requests_edit_guest_item: null,

  edit_match_tickets_request: null,
};

const matchTicketsReducer = (state = initialState, action: any) => {
  if (action.type === SET_MATCH_TICKET_LIST) {
    return update(state, {
      match_ticket_list: { $set: action.payload },
    });
  }

  if (action.type === SET_SELECTED_MATCH_TICKET) {
    return update(state, {
      selected_match_tickets: { $set: action.payload },
    });
  }

  if (action.type === SET_MATCH_TICKETS_REQUESTS) {
    return update(state, {
      match_tickets_requests: { $set: action.payload },
    });
  }

  if (action.type === SET_MATCH_TICKETS_REQUESTS_ITEM) {
    return update(state, {
      match_tickets_requests_item: { $set: action.payload },
    });
  }

  if (action.type === SET_MATCH_TICKETS_ADD_GUEST_DIALOG) {
    return update(state, {
      match_tickets_requests_add_guest_dialog: { $set: action.payload },
    });
  }

  if (action.type === SET_MATCH_TICKETS_REQUESTS_GUESTS_LIST) {
    return update(state, {
      match_tickets_requests_guests_list: { $set: action.payload },
    });
  }

  if (action.type === SET_MATCH_TICKETS_EDIT_GUEST_ITEM) {
    return update(state, {
      match_tickets_requests_edit_guest_item: { $set: action.payload },
    });
  }

  if (action.type === SET_MATCH_TICKETS_EDIT_GUEST_DIALOG) {
    return update(state, {
      match_tickets_requests_edit_guest_dialog: { $set: action.payload },
    });
  }

  if (action.type === SET_EDIT_MATCH_TICKETS_REQUEST) {
    return update(state, {
      edit_match_tickets_request: { $set: action.payload },
    });
  }

  return state;
};

export default matchTicketsReducer;
