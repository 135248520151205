import update from 'immutability-helper';
import {
  SET_ARTICLES,
  SET_ARTICLE_CREATE_DRAWER,
  SET_ARTICLE_DELETE_DIALOG,
  SET_ARTICLE_DETAILS,
  SET_ARTICLE_DETAILS_DRAWER,
  SET_ARTICLE_EDIT_DRAWER,
  SET_DISPLAY_REPORT_ITEM,
  SET_DISPLAY_REPORT_MODAL,
  SET_SORTING_ERROR,
} from './action_types';

const initialState = {
  articles: [],
  article_details: {},
  article_create_drawer: false,
  article_details_drawer: false,
  article_delete_dialog: false,
  sorting_error: false,
  article_edit_drawer: false,
  display_report_modal: false,
  display_report_item: null,
};

const articlesReducer = (state = initialState, action: any) => {
  if (action.type === SET_ARTICLES) {
    return update(state, {
      articles: { $set: action.payload },
    });
  }

  if (action.type === SET_ARTICLE_DETAILS) {
    return update(state, {
      article_details: { $set: action.payload },
    });
  }

  if (action.type === SET_ARTICLE_CREATE_DRAWER) {
    return update(state, {
      article_create_drawer: { $set: action.payload },
    });
  }

  if (action.type === SET_ARTICLE_DETAILS_DRAWER) {
    return update(state, {
      article_details_drawer: { $set: action.payload },
    });
  }

  if (action.type === SET_ARTICLE_DELETE_DIALOG) {
    return update(state, {
      article_delete_dialog: { $set: action.payload },
    });
  }

  if (action.type === SET_SORTING_ERROR) {
    return update(state, {
      sorting_error: { $set: action.payload },
    });
  }

  if (action.type === SET_ARTICLE_EDIT_DRAWER) {
    return update(state, {
      article_edit_drawer: { $set: action.payload },
    });
  }

  if (action.type === SET_DISPLAY_REPORT_MODAL) {
    return update(state, {
      display_report_modal: { $set: action.payload },
    });
  }

  if (action.type === SET_DISPLAY_REPORT_ITEM) {
    return update(state, {
      display_report_item: { $set: action.payload },
    });
  }

  return state;
};

export default articlesReducer;
