import update from 'immutability-helper';
import {
  SET_EDIT_MATCH_TICKET_TYPES_ITEM,
  SET_MATCH_TICKETS_TYPES_CREATE_DIALOG,
  SET_MATCH_TICKETS_TYPES_UPDATE_DIALOG,
  SET_MATCH_TICKET_TYPES_LIST,
} from './MatchTickets/action-types';
import { MatchTicketsTypesReducer } from './Types/match_tickets_types_reducer.types';

const initialState: MatchTicketsTypesReducer = {
  match_ticket_types_create_dialog: false,
  match_ticket_types_update_dialog: false,
  edit_match_ticket_type_item: null,
  match_ticket_types_list: null,
};

const matchTicketsTypesReducer = (state = initialState, action: any) => {
  if (action.type === SET_MATCH_TICKETS_TYPES_CREATE_DIALOG) {
    return update(state, {
      match_ticket_types_create_dialog: { $set: action.payload },
    });
  }

  if (action.type === SET_MATCH_TICKETS_TYPES_UPDATE_DIALOG) {
    return update(state, {
      match_ticket_types_update_dialog: { $set: action.payload },
    });
  }

  if (action.type === SET_EDIT_MATCH_TICKET_TYPES_ITEM) {
    return update(state, {
      edit_match_ticket_type_item: { $set: action.payload },
    });
  }

  if (action.type === SET_MATCH_TICKET_TYPES_LIST) {
    return update(state, {
      match_ticket_types_list: { $set: action.payload },
    });
  }

  return state;
};

export default matchTicketsTypesReducer;
