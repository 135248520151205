// MERCH CREATE
export const SET_MERCH_CREATE_MERCH_DIALOG =
  "merch/SET_MERCH_CREATE_MERCH_DIALOG";
export const SET_MERCH_UPDATE_MERCH_DIALOG =
  "merch/SET_MERCH_UPDATE_MERCH_DIALOG";
export const SET_MERCH_EDIT_ITEM = "merch/SET_MERCH_EDIT_ITEM";
export const SET_MERCH_LIST = "merch/SET_MERCH_LIST";
export const SET_DELETE_ITEM = "dialog/SET_DELETE_ITEM";

// MERCH TYPE CREATE
export const SET_MERCH_CREATE_MERCH_TYPE_DIALOG =
  "merch_type/SET_MERCH_CREATE_MERCH_TYPE_DIALOG";
export const SET_MERCH_UPDATE_MERCH_TYPE_DIALOG =
  "merch_type/SET_MERCH_UPDATE_MERCH_TYPE_DIALOG";
export const SET_MERCH_TYPE_EDIT_ITEM = "merchType/SET_MERCH_TYPE_EDIT_ITEM";
export const SET_MERCH_TYPE_LIST = "merch_type/SET_MERCH_TYPE_LIST";

export const SET_SELECTED_MERCH = "merch/SET_SELECTED_MERCH";
export const SET_MERCH_REQUESTS_LIST = "merch/SET_MERCH_REQUESTS_LIST";
export const SET_MERCH_REQUESTS_SELECTED_ITEM = "merch/SET_MERCH_REQUESTS_SELECTED_ITEM";
