import { createTheme } from '@mui/material/styles';

import asahi_desktop_regular from '../assets/fonts/asahi_desktop_regular.otf';
import asahi_regular from '../assets/fonts/asahi_regular.otf';
import asahi_app_regular from '../assets/fonts/asahi_app_regular.ttf';

import open_sans_medium from '../assets/fonts/OpenSans-SemiBold.ttf';
import open_sans_regular from '../assets/fonts/OpenSans-Regular.ttf';
import open_sans_bold from '../assets/fonts/OpenSans-Bold.ttf';
import open_sans_light from '../assets/fonts/OpenSans-Light.ttf';

import { createBreakpoints } from '@mui/system';
const breakpoints = createBreakpoints({});

export let theme = createTheme({
  palette: {
    primary: {
      main: '#BA131D',
    },
    secondary: {
      main: '#A1A0A3',
    },
    success: {
      main: '#42ba96',
    },
  },
  typography: {
    fontFamily: [
      'open_sans_regular',
      'open_sans_medium',
      'open_sans_bold',
      'open_sans_light',
      'asahi_regular',
      'asahi_desktop_regular',
      'asahi_app_regular',
    ].join(','),
    h1: {
      [breakpoints.only('xs')]: {
        fontSize: '16px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('sm')]: {
        fontSize: '18px',
        fontFamily: 'open_sans_medium',
      },
      [breakpoints.only('md')]: {
        fontSize: '24px',
        fontFamily: 'asahi_app_regular',
      },
      [breakpoints.only('lg')]: {
        fontSize: '36px',
        fontFamily: 'asahi_app_regular',
      },
      [breakpoints.only('xl')]: {
        fontSize: '48px',
        fontFamily: 'asahi_app_regular',
      },
    },
    h2: {
      [breakpoints.only('xs')]: {
        fontSize: '14px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('sm')]: {
        fontSize: '16px',
        fontFamily: 'open_sans_medium',
      },
      [breakpoints.only('md')]: {
        fontSize: '18px',
        fontFamily: 'open_sans_medium',
      },
      [breakpoints.only('lg')]: {
        fontSize: '24px',
        fontFamily: 'asahi_app_regular',
      },
      [breakpoints.only('xl')]: {
        fontSize: '36px',
        fontFamily: 'asahi_app_regular',
      },
    },
    h3: {
      [breakpoints.only('xs')]: {
        fontSize: '12px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('sm')]: {
        fontSize: '14px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('md')]: {
        fontSize: '16px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('lg')]: {
        fontSize: '18px',
        fontFamily: 'open_sans_medium',
      },
      [breakpoints.only('xl')]: {
        fontSize: '24px',
        fontFamily: 'asahi_app_regular',
      },
    },
    h4: {
      [breakpoints.only('xs')]: {
        fontSize: '10px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('sm')]: {
        fontSize: '12px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('md')]: {
        fontSize: '14px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('lg')]: {
        fontSize: '16px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('xl')]: {
        fontSize: '18px',
        fontFamily: 'open_sans_medium',
      },
    },
    h5: {
      [breakpoints.only('xs')]: {
        fontSize: '12px',
        fontFamily: 'open_sans_regular',
        lineHeight: 1.5,
      },
      [breakpoints.only('sm')]: {
        fontSize: '14px',
        fontFamily: 'open_sans_regular',
        lineHeight: 1.5,
      },
      [breakpoints.only('md')]: {
        fontSize: '16px',
        fontFamily: 'open_sans_regular',
        lineHeight: 1.5,
      },
      [breakpoints.only('lg')]: {
        fontSize: '18px',
        fontFamily: 'open_sans_regular',
        lineHeight: 1.5,
      },
      [breakpoints.only('xl')]: {
        fontSize: '24px',
        fontFamily: 'open_sans_medium',
        lineHeight: 1.5,
      },
    },
    h6: {
      [breakpoints.only('xs')]: {
        fontSize: '10px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('sm')]: {
        fontSize: '12px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('md')]: {
        fontSize: '14px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('lg')]: {
        fontSize: '16px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('xl')]: {
        fontSize: '18px',
        fontFamily: 'open_sans_medium',
      },
    },
    body1: {
      [breakpoints.only('xs')]: {
        fontSize: '12px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('sm')]: {
        fontSize: '14px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('md')]: {
        fontSize: '16px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('lg')]: {
        fontSize: '18px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('xl')]: {
        fontSize: '20px',
        fontFamily: 'open_sans_regular',
      },
    },
    body2: {
      [breakpoints.only('xs')]: {
        fontSize: '10px',
        fontFamily: 'open_sans_light',
      },
      [breakpoints.only('sm')]: {
        fontSize: '12px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('md')]: {
        fontSize: '14px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('lg')]: {
        fontSize: '16px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('xl')]: {
        fontSize: '18px',
        fontFamily: 'open_sans_regular',
      },
    },
    body3: {
      [breakpoints.only('xs')]: {
        fontSize: '10px',
        fontFamily: 'open_sans_light',
      },
      [breakpoints.only('sm')]: {
        fontSize: '12px',
        fontFamily: 'open_sans_light',
      },
      [breakpoints.only('md')]: {
        fontSize: '12px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('lg')]: {
        fontSize: '14px',
        fontFamily: 'open_sans_regular',
      },
      [breakpoints.only('xl')]: {
        fontSize: '16px',
        fontFamily: 'open_sans_regular',
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        *, *::before, *::after {
          box-sizing: inherit
        }
      
        html: {
          box-sizing: border-box
        }

        body {
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          background-color: #EAEAEA !important;
        }

        @font-face{
          font-family:"asahi_regular";
          src:url(${asahi_regular}) format("opentype");
        }

        @font-face{
          font-family:"asahi_desktop_regular";
          src:url(${asahi_desktop_regular}) format("opentype");
        }

        @font-face{
          font-family:"asahi_app_regular";
          src:url(${asahi_app_regular}) format("opentype");
        }

        @font-face{
          font-family:"open_sans_medium";
          src:url(${open_sans_medium}) format("opentype");
        }

        @font-face{
          font-family:"open_sans_regular";
          src:url(${open_sans_regular}) format("opentype");
        }

        @font-face{
          font-family:"open_sans_bold";
          src:url(${open_sans_bold}) format("opentype");
        }
        
        @font-face{
          font-family:"open_sans_light";
          src:url(${open_sans_light}) format("opentype");
        }
      `,
    },
    MuiToolbar: {
      defaultProps: {
        sx: {
          backgroundColor: 'white',
          boxShadow: '0px 3px 6px #00000029',
          borderBottom: 'none',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            backgroundColor: '#BA131D',
            color: 'white',
            fontSize: '24px',
            height: '70px',
            textTransform: 'capitalize',
            fontFamily: 'asahi_app_regular',
            '&:hover': {
              color: '#FFFFFF',
              backgroundColor: '#8E060F',
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            textTransform: 'initial',
            minWidth: 200,
            justifyContent: 'flex-start',
            backgroundColor: '#FFFFFF',
            color: '#000000',
            fill: '#000000',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029',
            border: '1px solid #A1A0A3',
            borderRadius: '4px',
            opacity: 1,
            '&:hover': {
              borderColor: '#BA131D',
              backgroundColor: 'white',
            },
          },
        },
      ],
    },
    MuiTab: {
      defaultProps: {
        sx: {
          fontFamily: 'asahi_app_regular',
        },
      },
    },
    MuiTableContainer: {
      defaultProps: {
        sx: { border: '1px solid #A1A0A3' },
      },
    },
    MuiSelect: {
      defaultProps: {
        style: {
          padding: 0,
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            padding: 0,
            fontSize: '16px',
            height: '40px',
            backgroundColor: 'white',
          },
        },
      ],
    },
    MuiMenuItem: {
      defaultProps: {
        style: {
          fontSize: '16px',
        },
      },
    },
    MuiInput: {
      defaultProps: {
        style: {
          height: '40px',
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: 'white',
            borderRadius: '4px',
            padding: '5px',
            fontSize: '16px',
            border: '1px solid #A1A0A3',
          },
        },
      ],
    },
    MuiOutlinedInput: {
      defaultProps: {
        style: {
          height: '40px',
          fontSize: '16px',
        },
      },
    },
  },
});
