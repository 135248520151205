import update from "immutability-helper";
import {
  SET_MERCH_CREATE_MERCH_TYPE_DIALOG,
  SET_MERCH_TYPE_EDIT_ITEM,
  SET_MERCH_TYPE_LIST,
  SET_MERCH_UPDATE_MERCH_TYPE_DIALOG,
} from "./action-types";

const initialState = {
  merch_type_edit_item: null,
  merch_type_list: null,
  merch_create_merch_type_dialog: false,
  merch_update_merch_type_dialog: false,
};

const merchTypeReducer = (state = initialState, action: any) => {
  if (action.type === SET_MERCH_CREATE_MERCH_TYPE_DIALOG) {
    return update(state, {
      merch_create_merch_type_dialog: { $set: action.payload },
    });
  }

  if (action.type === SET_MERCH_UPDATE_MERCH_TYPE_DIALOG) {
    return update(state, {
      merch_update_merch_type_dialog: { $set: action.payload },
    });
  }

  if (action.type === SET_MERCH_TYPE_EDIT_ITEM) {
    return update(state, {
      merch_type_edit_item: { $set: action.payload },
    });
  }
  if (action.type === SET_MERCH_TYPE_LIST) {
    return update(state, {
      merch_type_list: { $set: action.payload },
    });
  }

  return state;
};

export default merchTypeReducer;
