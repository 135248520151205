export function authHeader() {
  // return authorization header with jwt token
  let token = localStorage.getItem("token");

  if (token) {
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      "X-Requested-With": "XMLHttpRequest",
    };
  }
  return {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  };
}

export const API_URL = process.env.REACT_APP_API;
