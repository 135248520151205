import update from "immutability-helper";
import {
  SET_MERCH_CREATE_MERCH_DIALOG,
  SET_MERCH_EDIT_ITEM,
  SET_MERCH_LIST,
  SET_MERCH_REQUESTS_LIST,
  SET_MERCH_REQUESTS_SELECTED_ITEM,
  SET_MERCH_UPDATE_MERCH_DIALOG,
  SET_SELECTED_MERCH,
} from "./action-types";

const initialState = {
  merch_edit_item: null,
  merch_list: null,
  merch_create_merch_dialog: false,
  merch_update_merch_dialog: false,
  selected_merch: null,
  merch_requests_list: null,
  merch_requests_selected_item: null
};

const merchReducer = (state = initialState, action: any) => {
  if (action.type === SET_MERCH_CREATE_MERCH_DIALOG) {
    return update(state, {
      merch_create_merch_dialog: { $set: action.payload },
    });
  }

  if (action.type === SET_MERCH_UPDATE_MERCH_DIALOG) {
    return update(state, {
      merch_update_merch_dialog: { $set: action.payload },
    });
  }

  if (action.type === SET_MERCH_LIST) {
    return update(state, {
      merch_list: { $set: action.payload },
    });
  }

  if (action.type === SET_MERCH_EDIT_ITEM) {
    return update(state, {
      merch_edit_item: { $set: action.payload },
    });
  }

  if (action.type === SET_SELECTED_MERCH) {
    return update(state, {
      selected_merch: { $set: action.payload },
    });
  }

  if (action.type === SET_MERCH_REQUESTS_LIST) {
    return update(state, {
      merch_requests_list: { $set: action.payload },
    });
  }

  if (action.type === SET_MERCH_REQUESTS_SELECTED_ITEM) {
    return update(state, {
      merch_requests_selected_item: { $set: action.payload },
    });
  }

  return state;
};

export default merchReducer;
